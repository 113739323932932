import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
type Prop = {
    data: string;
    onChange: Function;
  };
const MyEditor = (prop:Prop) => {
//   const [content, setContent] = useState("<pre>"+prop.data+"</pre>");
let dataWithNewlines: any;
if(prop.data.includes('</div>')){
  // nếu data chứ thẻ </div> thì không replace
  dataWithNewlines = prop.data;
}else{
  dataWithNewlines = prop.data.replace(/\n/g, '<br>');
}
  const [content, setContent] = useState(dataWithNewlines);
    console.log('viet prop',prop);
  return (
    <Editor
      apiKey="t5hau2ir5mxswxlpmoehq7imjd4sfm507sw81n15cnalhiis" // Get your API key from TinyMCE
    //   initialValue={"<pre>"+content+"</pre>"}
      initialValue={content}
      init={{
        height: 500,
        menubar: false,
        // setup: function (editor) {
        //   editor.on('change', function () {
        //     console.log('viet change');
        //   editor.setContent(editor.getContent().replace(/<p>&nbsp;<\/p>/g, ''));
        //   });
        // },
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "link","code",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | \
alignleft aligncenter alignright alignjustify | \
bullist numlist outdent indent | removeformat | help | link | code",
      }}
      onEditorChange={newContent=> prop.onChange(newContent)}
    />
  );
};

export default MyEditor;
